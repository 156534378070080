const $target = document.querySelector("#write-and-delete");

const quotes = [
  "10+ Years experience in sector",
  "BS in Software Engineering",
  "Tech Lead / Lord of Software",
  "Clean Architecture Supporter ",
  "Still here, huh? Hmmm...",
  "SSR Visionary & Full-Stack Whisperer",
  "PageSpeed Guru & Lighthouse Savior",
  "Metal Band Contest Winner",
  "Rock Star by Night, Keyboards by Day",
  "Home Cook Extraordinaire",
  "Oh, C'mon! Don't you have a job?",
  "Dammit! I ran out of ideas!",
  "FINE! I'M NOT THAT AWESOME!",
  "...",
  "I'm Guybrush Threepwood! Mighty Pirate!!",
  "°·.¸.·°¯°·.¸.- MSN vibes -.¸.·°¯°·.¸.·°",
  "iddqd iddqd iddqd iddqd",
  "Oh BTW! do you know that I have..."
];

const config = {
  timeout: 2000,
  loop: true,
  speed: 50,
  errorQuota: 0,
  cursor: '_',
  cursorSpeed: 500,
};

writeAndDelete($target, quotes, config);